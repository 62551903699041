import {Link} from "react-router-dom";
import "./Planets.css"
import {useTranslation} from "react-i18next";
import React from "react";
export const Ton: React.FC = () => {
    return (
        <div>

            <Link to={"/skk"}>
                <div className={"ton"}>
                </div>
            </Link>
        </div>
    )
}

export const TonDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
                <div className={"description-planet-text"}>
                    <p></p>
                    <p>{t('celestialObject')}: TON 618</p>
                    <p>{t('workTitle')}: {t('skkTitle1')}</p>
                    <p>{t('skkTitle2')}</p>
                    <p>{t('workLanguages')}: ru</p>
                    <p>{t('skkAuthors1')}</p>
                    <p>{t('skkAuthors2')}</p>
                </div>
        </div>
    )
}