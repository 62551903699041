import React, { useState } from 'react';
import './SKK.css';
import {
    Eighteenth,
    Eighth,
    Eleventh, Fifteenth,
    Fifth,
    First, Fourteenth,
    Fourth, Nineteenth,
    Ninth,
    Second, Seventeenth,
    Seventh, Sixteenth,
    Sixth,
    Tenth,
    Third, Thirteenth,
    Twelfth
} from "./faces/Faces"
import useSound from "use-sound";
// @ts-ignore
import diceSound from "../../../static/dice.wav";

const diceSymbols = [
    <First />,
    <Second />,
    <Third />,
    <Fourth />,
    <Fifth />,
    <Sixth />,
    <Seventh />,
    <Eighth />,
    <Ninth />,
    <Tenth />,
    <Eleventh />,
    <Twelfth />,
    <Thirteenth />,
    <Fourteenth />,
    <Fifteenth />,
    <Sixteenth />,
    <Seventeenth />,
    <Eighteenth />,
    <Nineteenth />
];


const SKK: React.FC = () => {
    const [currentFace, setCurrentFace] = useState(diceSymbols[0]);
    const [rolling, setRolling] = useState(false);
    const [playButton] = useSound(diceSound, {volume: 1} );

    const rollDice = () => {
        setRolling(true);
        playButton()
        setTimeout(() => {
            const randomFace = diceSymbols[Math.floor(Math.random() * diceSymbols.length)];
            setCurrentFace(randomFace);
            setRolling(false);
        }, 1000);
    };

    return (
        <div className={"skk"}>
            <div className="dice-container">
                <p>ТОРЖЕСТВО МНОГОТОЧИЯ</p>
                <p>(негодная арабская геомантия)</p>
                <div className={`dice-face ${rolling ? 'rolling' : ''}`}>
                    {currentFace}
                </div>
                <button onClick={rollDice} disabled={rolling}>
                    {rolling ? 'Rolling...' : 'Roll Dice'}
                </button>
            </div>
        </div>
    );
};

export default SKK;
