import React, { useEffect, useState } from 'react';
import "./MB.css";
import { useTheme } from "../../../utils/ThemeProvider";
import { MBColor } from "../../../const/Colors";
import { imageUrls } from "./Images";
import npNOTFOUND from "../../../static/mb/npNOTFOUND.jpeg";
import {Link} from "react-router-dom";
import useSound from "use-sound";
// @ts-ignore
import page from "../../../static/mb/page.wav";

const Collection: React.FC = () => {
    const [foundImages, setFoundImages] = useState<string[]>([]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const { setBackground } = useTheme();
    const [playButton] = useSound(page, {volume: 1} );


    useEffect(() => {
        setBackground(MBColor);
        const savedFoundImages = JSON.parse(localStorage.getItem('foundImages') || '[]');
        setFoundImages(savedFoundImages);
    }, [setBackground]);

    const handleImageClick = (image: string) => {
        if (foundImages.includes(image)) {
            setSelectedImage(image);
            playButton()
        }
    };

    const closeModal = () => {
        setSelectedImage(null);
        playButton()
    };

    return (
        <div className="mb">
            <Link to={"/mb"}><div className={"text menu-buttons back"}>назад</div></Link>
            <div className="collection">
                {imageUrls.map((image, index) => (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img
                        key={index}
                        src={foundImages.includes(image) ? image : npNOTFOUND}
                        alt={`Image ${index}`}
                        onClick={() => handleImageClick(image)}
                    />
                ))}
            </div>

            {selectedImage && (
                <div className="modal" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <img className="modal-content" src={selectedImage} alt="Selected"/>
                </div>
            )}
        </div>
    );
};

export default Collection;
