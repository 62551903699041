// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faces-text{
    p {
        color: black;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70vw;
        box-sizing: border-box;
        white-space: nowrap;
    }
    .center{
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

    }

    /*MOB*/
    @media (max-width: 700px) {
        .center{
            font-size: 2.5vw;
        }
        .container{
            font-size: 2.5vw;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/authors/skk/faces/Faces.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;IAChB;IACA;QACI,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,WAAW;QACX,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,mBAAmB;;IAEvB;;IAEA,MAAM;IACN;QACI;YACI,gBAAgB;QACpB;QACA;YACI,gBAAgB;QACpB;IACJ;AACJ","sourcesContent":[".faces-text{\n    p {\n        color: black;\n    }\n    .container {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 70vw;\n        box-sizing: border-box;\n        white-space: nowrap;\n    }\n    .center{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        white-space: nowrap;\n\n    }\n\n    /*MOB*/\n    @media (max-width: 700px) {\n        .center{\n            font-size: 2.5vw;\n        }\n        .container{\n            font-size: 2.5vw;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
