import React from 'react';
import './Planets.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const PlanetRune = () => {
    return (
        <div className={"planet-container"}>
            <Link to={"/tm"}>
                <div className="planet-rune"></div>
            </Link>
                {/*<div className="moon"></div>*/}
            {/*<div className="moon"></div>*/}
        </div>
    );
};

export const PlanetRuneDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
            <div className={"description-planet-text"}>
                <p>{t('celestialObject')}: HD 189733b</p>
                <p>{t('workTitle')}: {t('tmTitle')}</p>
                <p>{t('workLanguages')}: ru</p>
                <p>{t('tmAuthors1')}</p>
                <p>{t('tmAuthors2')}</p>
            </div>
        </div>
    )
}

