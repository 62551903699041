import React, {useState} from "react";
import i18n from "i18next";
import "./Panel.css"
import {useTranslation} from "react-i18next";
import useSound from "use-sound";
// @ts-ignore
import buttonSound from "../../../static/button.wav";
import {Langs} from "../../../const/Interfaces";

interface PanelProps {
    prevSlide: any;
    nextSlide: any;
    panel: any;
    setPanel: any;
}

export const Panel: React.FC<PanelProps> = (PanelProps) =>{
    const [playButton] = useSound(buttonSound, {volume: 1} );
    const [lang, setLang] = useState<Langs>(i18n.language === "en-US" ? "en" : "ru")
    const changeLanguage = () => {
        playButton()
        let newLang: Langs = i18n.language === "ru" ? "en" : "ru"
        i18n.changeLanguage(newLang);
        setLang(newLang)
    };

    const handleClickOn = () => {
        playButton()
        PanelProps.setPanel(!PanelProps.panel)
    }

    const handleNext = () => {
        playButton()
        PanelProps.nextSlide()
    }

    const handlePrev = () => {
        playButton()
        PanelProps.prevSlide()
    }

    const { t } = useTranslation();
    return (
        <div className={"panel"}>
            { PanelProps.panel && <>
            <div onClick={() => {
                changeLanguage()
            }} className={"button"}>
                <div className={"frame-doted lang frame-doted-text-center"}>
                    <div className={"frame-doted-text"}>
                        {lang}
                    </div>
                </div>
            </div>
            <div onClick={handlePrev} className={"button"}>
                <div className={"frame-doted prev"}>
                    <div className={"frame-doted-text frame-doted-text-center"}>
                        ‹
                    </div>
                </div>
            </div>
            <div onClick={handleNext} className={"button"}>
                <div className={"frame-doted next"}>
                    <div className={"frame-doted-text frame-doted-text-center"}>
                        ›
                    </div>
                </div>
            </div>
            </> }
            <div onClick={handleClickOn} className={"button"}>
                <div  className={"frame-doted on"}>
                    <div style={PanelProps.panel ? {color: "green"}: {color: "#690909"}} className={"frame-doted-text frame-doted-text-center"}>
                        {t('onOf')}
                    </div>
                </div>
            </div>
        </div>
    )
}