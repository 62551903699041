import np1 from "../../../static/mb/np1.jpg";
import np2 from "../../../static/mb/np2.jpg";
import np3 from "../../../static/mb/np3.jpg";
import np4 from "../../../static/mb/np4.jpg";
import np5 from "../../../static/mb/np5.jpg";
import np6 from "../../../static/mb/np6.jpg";
import np7 from "../../../static/mb/np7.jpg";
import np8 from "../../../static/mb/np8.jpg";
import np9 from "../../../static/mb/np9.jpg";
import np10 from "../../../static/mb/np10.jpg";
import np11 from "../../../static/mb/np11.jpg";
import np12 from "../../../static/mb/np12.jpg";
import np13 from "../../../static/mb/np13.jpg";
import np14 from "../../../static/mb/np14.jpg";
import np15 from "../../../static/mb/np15.jpg";

export const imageUrls: string[] = [
    np1,
    np2,
    np3,
    np4,
    np5,
    np6,
    np7,
    np8,
    np9,
    np10,
    np11,
    np12,
    np13,
    np14,
    np15
];



export const preloadImage = (src: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
    });
};