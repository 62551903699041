import "./Planets.css"
import {useTranslation} from "react-i18next";
import {AliDd, AliNs, AliUl} from "../../../const/Urls";
import React from "react";
export const PlanetKepler: React.FC = () => {
    const handleObjClick = (url: string) => {
        switch (url) {
            case "ns":
                window.location.href = AliNs
                break
            case "dd":
                window.location.href = AliDd
                break
            case "ul":
                window.location.href = AliUl
        }
        if (url === "ns"){
        } else {
        }
    };
    return (
        <div className="planet">
            <div onClick={() => handleObjClick("dd")} className="kepler planet"></div>
            <div className="sputnik-orbit">
                <div onClick={() => handleObjClick("ns")} className="sputnik"></div>
            </div>
            <div className="spaceship" onClick={() => handleObjClick("ul")}></div>
        </div>
    );
}

export const PlanetKeplerDescription: React.FC = () => {
    const {t} = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
            <div className={"description-planet-text"}>
                <p>{t('celestialObject')}: Kepler-296 e</p>
                <p>{t('workTitle')}: {t('aliTitle')}</p>
                <p>{t('workLanguages')}: ru, en</p>
                <p>{t('aliAuthors')}</p>
                <p>{t('aliAuthors2')}</p>
            </div>
        </div>
    )
}