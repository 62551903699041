import React, { useEffect, useMemo, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { isMobileDeviceV3, removeElementsByClass } from "../../utils/StyleUtils";
import { MobileWidth } from "../../const/Style";
import "./Homepage.css";
import { PlanetKepler, PlanetKeplerDescription } from "./planets/PlanetKepler";
import { Ton, TonDescription } from "./planets/Ton";
import GalaxyImg1 from "../../static/galaxy1.png";
import GalaxyImg2 from "../../static/galaxy2.png";
import GalaxyImg3 from "../../static/galaxy3.png";
import { Panel } from "./_inner/Panel";
import { PlanetSaturn, PlanetSaturnDescription } from "./planets/PLanetSaturn";
import { useTheme } from "../../utils/ThemeProvider";
import { Black } from "../../const/Colors";
import { useSearchParams } from "react-router-dom";
import { SeaOfPo, SeaOfPoDescription } from "./planets/SeaOfPo";
import { PlanetNiko, PlanetNikoDescription } from "./planets/PlanetNiko";
import { PlanetRune, PlanetRuneDescription } from "./planets/PlanetRune";

const Homepage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialIndex = parseInt(searchParams.get('index') || "0", 10);
    const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
    const [panel, setPanel] = useState<boolean>(true);
    const listGalaxies = useMemo(() => [GalaxyImg1, GalaxyImg2, GalaxyImg3], []);
    const [loadedImg, setLoadedImg] = useState(0);
    const isMobile: boolean = isMobileDeviceV3(MobileWidth);
    const rStars = isMobile ? 0.2 : 1;
    const stars = Array.from({ length: 1000 }, (_, index) => ({
        id: index,
        x: Math.random() * 100,
        y: Math.random() * 100,
        wh: Math.random() + rStars
    }));

    const planets = [
        <PlanetNiko />,
        <PlanetKepler />,
        <Ton />,
        <PlanetRune />,
        <PlanetSaturn />,
        <SeaOfPo />
    ];
    const planetsDescriptions = [
        <PlanetNikoDescription />,
        <PlanetKeplerDescription />,
        <TonDescription />,
        <PlanetRuneDescription />,
        <PlanetSaturnDescription />,
        <SeaOfPoDescription />
    ];
    const { setBackground } = useTheme();

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % planets.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + planets.length) % planets.length);
    };

    const handlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: prevSlide,
        trackMouse: true,
        preventScrollOnSwipe: true
    });

    useEffect(() => {
        setSearchParams({ index: currentIndex.toString() });
    }, [currentIndex, setSearchParams]);

    useEffect(() => {
        listGalaxies.forEach((galaxy) => {
            const img = new Image();
            img.onload = () => setLoadedImg((prevLoadedImg) => prevLoadedImg + 1);
            img.src = galaxy;
        });
    }, [listGalaxies]);

    useEffect(() => {
        setBackground(Black);
    }, [setBackground]);

    useEffect(() => {
        if (loadedImg >= 3) {
            const sky = document.getElementById('star-sky');
            if (!sky) return;

            removeElementsByClass("galaxy");
            for (let i = 0; i < 3; i++) {
                const galaxy = document.createElement('img');
                const randomNum = Math.floor(Math.random() * 3);
                galaxy.src = listGalaxies[randomNum];
                galaxy.className = 'galaxy';
                galaxy.style.position = 'absolute';
                galaxy.style.left = `${Math.random() * 100}%`;
                galaxy.style.top = `${Math.random() * 100}%`;
                galaxy.style.transform = `translate(-50%, -50%) scale(${Math.random() * 0.5 + 2.0}) rotate(${Math.random() * 100}deg)`;
                galaxy.style.width = '1rem';
                sky.appendChild(galaxy);
            }
        }
    }, [listGalaxies, loadedImg]);

    return (
        <div className="homepage" {...handlers}>
            <Panel prevSlide={prevSlide} nextSlide={nextSlide} panel={panel} setPanel={setPanel} />
            <div id="star-sky" className="star-sky">
                {stars.map(star => (
                    <div
                        key={star.id}
                        className="star"
                        style={{ left: `${star.x}%`, top: `${star.y}%`, width: star.wh, height: star.wh }}
                    />
                ))}
                {panel && planetsDescriptions[currentIndex]}
                <div className="slider-container">
                    <div className={panel && isMobile ? "slider marg-mob" : "slider"} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {planets.map((planet, index) => (
                            <div className="slide" key={index}>
                                {planet}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
