import "./Faces.css"
export const First = () => {
    const containers = Array.from({ length: 4 });

    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Адам Мицкевич
            </div>
            <div className={"center"}>
                «Видение ксендза Петра»
            </div>
            {containers.map((_, index) => (<div className={"container center"}>
                <p>… … … … … … … … … … …</p>
            </div>))}
        </div>
    )
}

export const Second = () => {
    const containers = Array.from({length: 11});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Иисус Христос
            </div>
            <div className={"center"}>
                «Воскресение»
            </div>
            {containers.map((_, index) => (<div style={{width: "30vw"}} className={"container"}>
                <div>…</div>
                <div>…</div>
                <div>…</div>
            </div>))}
        </div>
    )
}

export const Third = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Платон
            </div>
            <div className={"center"}>
                «Благо»
            </div>
            {containers.map((_, index) => (<div className={"center"}>...</div>))}
        </div>
    )
}

export const Fourth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Роберт Стивенсон
            </div>
            <div className={"center"}>
                «Доктор Джекилл и мистер Хайд»
            </div>
            {containers.map((_, index) => (
                <>
                    <div className={"center"}>...</div>
                    <div style={{marginLeft: "1.5rem"}} className={"center"}>...</div>
                </>
            ))}
        </div>
    )
}

export const Fifth = () => {
    const containers = Array.from({length: 3});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Феофил Антиохийский
            </div>
            <div className={"center"}>
                «Против Автолика...»
            </div>
            {containers.map((_, index) => (
                    <div className={"center"}>...</div>
            ))}
        </div>
    )
}

export const Sixth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Джером К. Джером
            </div>
            <div className={"center"}>
                «Трое в лодке, не считая собаки»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>... ... ...</div>
            ))}
            <div style={{marginLeft: "4rem"}} className={"center"}>...</div>
        </div>
    )
}

export const Seventh = () => {
    const containers = Array.from({length: 4});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Братья Гримм
            </div>
            <div className={"center"}>
                «Бременские музыканты»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>...</div>
            ))}
            <div style={{marginLeft: "2rem"}} className={"center"}>...</div>
        </div>
    )
}

export const Eighth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Михаил Булгаков
            </div>
            <div className={"center"}>
                «Мастер и Маргарита»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>
                    ... ... ... <div style={{width: "1rem"}}></div> ... ... ...
                </div>
            ))}
        </div>
    )
}

export const Ninth = () => {
    const containers = Array.from({length: 3});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Моисей
            </div>
            <div className={"center"}>
                «Книга Бытия»
            </div>
            <div className={"center"}>...</div>
            {containers.map((_, index) => (
                <div className={"center"}>
                    ...<div style={{width: "2rem"}}></div>...
                </div>
            ))}
        </div>
    )
}

export const Tenth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Лев Толстой
            </div>
            <div className={"center"}>
                «Теремок»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>
                    … … … … … …
                </div>
            ))}
            <div className={"center"}>
                … …
            </div>
        </div>
    )
}

export const Eleventh = () => {
    const containers = Array.from({length: 3});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Гомер
            </div>
            <div className={"center"}>
                «Мои музы»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>
                    … … …
                </div>
            ))}
        </div>
    )
}

export const Twelfth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Пифагор Самосский
            </div>
            <div className={"center"}>
                «Декада»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>
                    … … …
                    <div style={{width: "2rem"}}></div>
                    … … …
                    <div style={{width: "2.5rem"}}></div>
                    … … … …
                </div>
            ))}
        </div>
    )
}

export const Thirteenth = () => {
    const containers = Array.from({length: 1});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Пауло Коэльо
            </div>
            <div className={"center"}>
                «Приключения проститутки Марии»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>… … … … … … … … … … … </div>
            ))}
        </div>
    )
}

export const Fourteenth = () => {
    const containers = Array.from({length: 11});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Мария Клокова-Лапина
            </div>
            <div className={"center"}>
                «Весёлые гуси»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>… …</div>
            ))}
        </div>
    )
}

export const Fifteenth = () => {
    const containers = Array.from({length: 11});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Гесиод
            </div>
            <div className={"center"}>
                «Пандора»
            </div>
            {containers.map((_, index) => (
                <div className={"center"}>… … … … …</div>
            ))}
        </div>
    )
}

export const Sixteenth = () => {
    const containers = Array.from({length: 3});
    return (
        <div className={"faces-text"}>
            <div   className={"center"}>
                Иоанн Богослов
            </div>
            <div   className={"center"}>
                «Апокалипсис»
            </div>
            {containers.map((_, index) => (
                <div   className={"center"}>… … … … … … … … … … … … … … … … … … … … … … </div>
            ))}
        </div>
    )
}

export const Seventeenth = () => {
    const containers = Array.from({length: 11});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                «Каша из топора»
            </div>
            <div style={{width: "2rem"}}></div>
            {containers.map((_, index) => (
                <div className={"center container"}>
                    …<div style={{width: "3rem"}}></div>
                    …<div style={{width: "3rem"}}></div>
                    …<div style={{width: "3rem"}}></div>
                    …<div style={{width: "3rem"}}></div>
                    …<div style={{width: "3rem"}}></div>
                    …<div style={{width: "3rem"}}></div>
                    …
                </div>
            ))}
        </div>
    )
}

export const Eighteenth = () => {
    const containers = Array.from({length: 10});
    return (
        <div className={"faces-text"}>
            <div className={"center"}>
                Фредерик Бегбедер
            </div>
            <div className={"center"}>
                «Исповедь сына тысячелетия»
            </div>
            <div style={{width: "2rem"}}></div>
            {containers.map((_, index) => (
                <div className={"center container"}>… … … … … … … … … … </div>
            ))}
            <div className={"center container"}>… … … … … … … … …</div>

        </div>
    )
}

export const Nineteenth = () => {
    const containers = Array.from({length: 3});
    return (
        <div className={"faces-text"}>
            <div   className={"center"}>
                Роберт Рождественский
            </div>
            <div   className={"center"}>
                «люблю, целую»
            </div>
            <div style={{width: "2rem"}}></div>
            {containers.map((_, index) => (
                <div   className={"center"}>… … … … … … … … … … … … … … … … … … … … … … … …
                </div>
            ))}
            <div    className={"center"}>… … … … … … … … … … … … … … … …</div>
        </div>
    )
}