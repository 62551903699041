// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel{
    .button {
        cursor: pointer;
    }
    .lang{
        position: absolute;
        width: 2rem;
        left: 5%;
        top: 1rem;
    }
    .prev{
        position: absolute;
        width: 2rem;
        left: 5%;
        bottom: 1rem;
    }
    .next{
        position: absolute;
        width: 2rem;
        right: 5%;
        bottom: 1rem;
    }
    .on{
        position: absolute;
        width: 2rem;
        right: 5%;
        top: 1rem;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/homepage/_inner/Panel.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;IACnB;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,QAAQ;QACR,SAAS;IACb;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,QAAQ;QACR,YAAY;IAChB;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,SAAS;QACT,YAAY;IAChB;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,SAAS;QACT,SAAS;IACb;;AAEJ","sourcesContent":[".panel{\n    .button {\n        cursor: pointer;\n    }\n    .lang{\n        position: absolute;\n        width: 2rem;\n        left: 5%;\n        top: 1rem;\n    }\n    .prev{\n        position: absolute;\n        width: 2rem;\n        left: 5%;\n        bottom: 1rem;\n    }\n    .next{\n        position: absolute;\n        width: 2rem;\n        right: 5%;\n        bottom: 1rem;\n    }\n    .on{\n        position: absolute;\n        width: 2rem;\n        right: 5%;\n        top: 1rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
