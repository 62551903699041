import React from "react";
import './Tm.css';

const textLines = [
    [
        { text: "Звезда", className: "d33 d" },
        { text: "печатает", className: "d96 d" },
        { text: "скрижаль", className: "d111 d" }
    ],
    [
        { text: "Код", className: "d33 d" },
        { text: "–", className: "dash d" },
        { text: "кладка", className: "d44 d" }
    ],
    [
        { text: "воздух", className: "d77 d" },
        { text: "дрожит", className: "d77 d" }
    ],
    [
        { text: "тьма", className: "d65 d" },
        { text: "растит", className: "d88 d" },
        { text: "месяц", className: "d96 d" }
    ],
    [
        { text: "снята", className: "d88 d" },
        { text: "печать", className: "d99 d" }
    ],
    [
        { text: "плоть", className: "d96 d" },
        { text: "радуется", className: "d123 d" }
    ],
    [
        { text: "Мать", className: "d65 d" },
        { text: "даёт", className: "d33 d" },
        { text: "любовь", className: "d96 d" },
        { text: "ребёнку", className: "d81 d" }
    ],
    [
        { text: "Пламень", className: "d96 d" },
        { text: "отцу", className: "d81 d" }
    ]
];

type TextDivsProps = {
    currentNum: number
}

const TextDivs: React.FC<TextDivsProps> = ({currentNum}) => {
    return (
        <div className="text-divs-container">
            {textLines.map((line, lineIndex) => (
                <div key={lineIndex} className="inline">
                    {line.map((item, wordIndex) => {
                        const className = item.className.includes(`d${currentNum}`) ? `d-active d`: "d";
                        return (
                            <div key={wordIndex} className={className}>
                                {item.text}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default TextDivs;
