import React, {createContext, useContext, useState, ReactNode, useEffect} from 'react';

interface ThemeContextType {
    background: string;
    setBackground: (color: string) => void;
}

const ThemeContext = createContext<ThemeContextType>({
    background: '#fff',
    setBackground: () => {}
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
    children: ReactNode;
    defaultColor: string | '#ffffff';
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, defaultColor }) => {
    const [background, setBackground] = useState<string>(defaultColor);
    useEffect(() => {
        document.body.style.backgroundColor = background;
    }, [background]);
    return (
        <ThemeContext.Provider value={{ background, setBackground }}>
            {children}
        </ThemeContext.Provider>
    );
};
