import React from "react";
import "./Planets.css";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SeaOfPoUrl} from "../../../const/Urls";

export const SeaOfPo: React.FC = () => {


    return (
        <div className="sea-of-po">
            <Link to={SeaOfPoUrl}>
            <div className="planetPo">
                <div className="ocean"></div>
            </div>
            </Link>
        </div>
    );
};

export const SeaOfPoDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
            <div className={"description-planet-text "}>
                <p>{t('celestialObject')}: TOI-1452</p>
                <p>{t('workTitle')}: {t('aeTitle')}</p>
                <p>{t('workLanguages')}: ru, en</p>
                <p>{t('aeAuthors1')}</p>
                <p>{t('aeAuthors2')}</p>
            </div>
        </div>
    )
}

