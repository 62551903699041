// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-doted {
    border-color: aqua;
    border-style: dashed;
    border-width: 0.01rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 0.05rem;
}

.frame-doted-text{
    padding: 0.5rem;
    color: white;
}
.frame-doted-text-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".frame-doted {\n    border-color: aqua;\n    border-style: dashed;\n    border-width: 0.01rem;\n    margin-bottom: 1rem;\n    box-sizing: border-box;\n    border-radius: 0.05rem;\n}\n\n.frame-doted-text{\n    padding: 0.5rem;\n    color: white;\n}\n.frame-doted-text-center{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
