import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../../utils/ThemeProvider";
import { Black } from "../../../const/Colors";
import './Tm.css';
import TextDivs from "./TextDivs";

const availableNumbers: number[] = [33, 96, 111, 44, 77, 65, 88, 96, 99, 123, 81];

const getRandomNumber = (): number => {
    return availableNumbers[Math.floor(Math.random() * availableNumbers.length)];
}

const getRandomPosition = (max: number): number => {
    return Math.floor(Math.random() * max);
}

const Tm: React.FC = () => {
    const { setBackground } = useTheme();
    const squareRef = useRef<HTMLDivElement>(null);
    const cubeRef = useRef<HTMLDivElement>(null);
    const poemRef = useRef<HTMLDivElement>(null);
    const [number, setNumber] = useState<number>(getRandomNumber());

    useEffect(() => {
        setBackground(Black);

        const moveSquare = () => {
            const square = squareRef.current;
            if (!square) return;

            const cube = cubeRef.current;
            if (!cube) return;

            const cubeRect = cube.getBoundingClientRect();
            let x = getRandomPosition(cubeRect.width - 50) // Subtract square width
            let y = getRandomPosition(cubeRect.height - 50); // Subtract square height
            let dx = 1;
            let dy = 1;

            const updatePosition = () => {
                if (x + 50 >= cubeRect.width || x <= 0) { // Adjust boundaries
                    dx = -dx;
                    setNumber(getRandomNumber());
                }
                if (y + 50 >= cubeRect.height || y <= 0) { // Adjust boundaries
                    dy = -dy;
                    setNumber(getRandomNumber());
                }

                x += dx;
                y += dy;

                square.style.left = `${x}px`;
                square.style.top = `${y}px`;

                requestAnimationFrame(updatePosition);
            };

            updatePosition();
        };

        moveSquare();
    }, [setBackground]);

    return (
        <div className={"tm"}>
            <div className="tm-container">
                <div className="grid">
                    <div className="poem" ref={poemRef}>
                        <div className={"text-container"}>
                            <TextDivs currentNum={number}/>
                        </div>
                    </div>
                    <div className="cube" ref={cubeRef} style={{position: "relative"}}>
                        <div className="moving-square" ref={squareRef}>
                            {number}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Tm;
