import React, {  useState } from "react";
import { imageUrls } from "./Images";
import "./MB.css";
import { shuffleArray } from "../../../utils/DataUtils";
import {PlayFunction} from "use-sound/dist/types";

const folderNames: string[][] = [
    ["оплывшее", "золотое", "облако", "на", "парадном", "портрете", "вполоборота"],
    ["звенит", "у", "дальних", "дверей", "монетами", "Случайный", "Попутчик"],
    ["невозможно", "&", "необходимо:", "среди", "посмертных", "архивов"],
    ["и", "телевизионные", "цветы", "распускались", "под", "веками", "пятнами", "белей", "молока"],
    ["смахивая", "пыль", "крошки", "сдувая", "встреча", "окончена", "время", "уйти"],
    ["нарывают", "взлётно-посадочные", "Входы", "&", "Выходы"],
    ["Входов", "&", "Выходов", "Учреждений", "путем", "коммунальных", "огней"],
    ["вечером", "дальних", "дверей", "неповторимое", "повторяя"],
    ["оно", "убито", "&", "похоронено", "но", "ему", "не", "впервой"],
    ["в", "каждом", "конце", "коридора", "Случайный", "Попутчик", "засыпает", "у", "меня", "на", "коленях"],
    ["нас", "отпустили", "&", "хлопают", "дальние", "двери"],
    ["как", "Позабытое", "&", "лежащее", "без", "дела"],
    ["и", "вспухал", "узлами", "Изменённый", "проспект", "корпусов", "&", "фасадов"],
    ["все", "незабываемое", "забывая"]
];

type BreadcrumbProps = {
    path: string[];
    navigateTo: (index: number) => void;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ path, navigateTo }) => (
    <div className="breadcrumb">
        <span onClick={() => navigateTo(-1)}>
            {"... "}
        </span>
        {path.map((folder, index) => (
            <span key={index} onClick={() => navigateTo(index)}>
                {folder} {index < path.length - 1 ? '>' : ''}
            </span>
        ))}
    </div>
);

type FolderProps = {
    folderName: string;
    openFolder: (folderName: string, folderIndex: number) => void;
    folderIndex: number;
};

const Folder: React.FC<FolderProps> = ({ folderName, openFolder, folderIndex }) => (
    <div className="folder" onClick={() => openFolder(folderName, folderIndex)}>
        📁{folderName}
    </div>
);

const initCurrentFolders = () => {
    let firstFolder: string[] = [];
    folderNames.forEach((el) => {
        firstFolder.push(el[0]);
    });
    return shuffleArray(firstFolder);
};

type FoldersProps = {
    foundImages: string[]
    addImage: any
    playButton: PlayFunction
}
const Folders: React.FC<FoldersProps> = ({foundImages, addImage, playButton}) => {
    // localStorage.removeItem('foundImages')
    const [path, setPath] = useState<string[]>([]);
    const [imageInFolder, setImageInFolder] = useState<number>(14);
    const [currentFolders, setCurrentFolders] = useState<string[]>(initCurrentFolders());
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const closeModal = () => {
        setSelectedImage(null);
        playButton()
    };

    const openFolder = (folderName: string, folderIndex: number) => {
        const newPath = [...path, folderName];
        setPath(newPath);

        const correctSubArrayIndex = folderNames.findIndex(subArray => subArray[0] === newPath[0]);
        const correctSubArray = folderNames[correctSubArrayIndex];

        if (correctSubArray && newPath.join(' ') === correctSubArray.slice(0, newPath.length).join(' ')) {
            if (newPath.length === correctSubArray.length) {
                setImageInFolder(correctSubArrayIndex)
                setCurrentFolders([])
            } else {
                setImageInFolder(-1)
                setCurrentFolders(shuffleArray(correctSubArray.slice(newPath.length)));
            }
        } else {
            setImageInFolder(-1)
            setCurrentFolders(shuffleArray(correctSubArray.slice(newPath.length - 1)));
        }
    };

    const navigateTo = (index: number) => {
        if (index === -1) {
            setPath([]);
            setCurrentFolders(initCurrentFolders());
            setImageInFolder(14)
        } else if (index === path.length - 1){
            return
        } else {
            const newPath = path.slice(0, index + 1);
            setPath(newPath);
            setImageInFolder(-1)

            const correctSubArray = folderNames.find(subArray => subArray[0] === newPath[0]);
            if (correctSubArray) {
                setCurrentFolders(shuffleArray(correctSubArray.slice(newPath.length)));
            } else {
                setCurrentFolders(initCurrentFolders());
            }
        }
    };

    const HandleClickImg = () => {
        addImage(imageUrls[imageInFolder])
        setSelectedImage(imageUrls[imageInFolder])
    }

    return (
        <div className="folders">
            <Breadcrumb path={path} navigateTo={navigateTo} />
            <div className="folder-container">
                {currentFolders.map((folderName, index) => (
                    <Folder key={index} folderIndex={index} folderName={folderName} openFolder={openFolder} />
                ))}
                {imageInFolder !== -1 && (
                    <div className="found-image">
                        <img onClick={HandleClickImg} className="folder-image" src={imageUrls[imageInFolder]} alt="Found" />
                    </div>
                )}
            </div>
            {selectedImage && (
                <div className="modal" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <img className="modal-content" src={selectedImage} alt="Selected"/>
                </div>
            )}

        </div>
    );
};

export default Folders;
