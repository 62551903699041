import React from 'react';
import './Planets.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const PlanetSaturn: React.FC = () => {
    return (
        <div className="planet">
            <Link to={"/mb"}>
            <div className="saturn">
                <div className="ring"></div>
            </div>
            </Link>
        </div>
    );
};

export const PlanetSaturnDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
            <div className={"description-planet-text "}>
                <p>{t('celestialObject')}: Saturn</p>
                <p>{t('workTitle')}: {t('mbTitle')}</p>
                <p>{t('workLanguages')}: ru</p>
                <p>{t('mbAuthors1')}</p>
                <p>{t('mbAuthors2')}</p>
            </div>
        </div>
    )
}

