import React, { useEffect, useState } from "react";
import { useTheme } from "../../../utils/ThemeProvider";
import { NikoColor } from "../../../const/Colors";
import "./Niko.css";
import title from "../../../static/niko/title.gif";
import man from "../../../static/niko/man.gif";
import naked from "../../../static/niko/nakedfenomen.gif";
import weed from "../../../static/niko/weed.gif";
import vagina from "../../../static/niko/vagina.gif";
import pray from "../../../static/niko/pray.gif";
import tapestries from "../../../static/niko/tapestries.gif";
import unicorn from "../../../static/niko/unicorn.gif";
import hand from "../../../static/niko/hand.gif";
import horny from "../../../static/niko/horny.gif";
import angel from "../../../static/niko/angel2.gif";
import flowerglitch from "../../../static/niko/flowerglitch.gif";
import cometa from "../../../static/niko/comet.gif";
import text1 from "../../../static/niko/1.jpg";
import text2 from "../../../static/niko/2.jpg";
import text3 from "../../../static/niko/3.jpg";
import text4 from "../../../static/niko/4.jpg";
import text5 from "../../../static/niko/5.jpg";
import text6 from "../../../static/niko/6.jpg";
import text7 from "../../../static/niko/7.jpg";
import text8 from "../../../static/niko/8.jpg";
import text9 from "../../../static/niko/9.jpg";
import text10 from "../../../static/niko/10.jpg";
import text11 from "../../../static/niko/11.jpg";
import text12 from "../../../static/niko/12.jpg";
import text13 from "../../../static/niko/13.jpg";
import text14 from "../../../static/niko/14.jpg";
import text15 from "../../../static/niko/15.jpg";

const imageUrls = [
    title,
    man,
    naked,
    weed,
    vagina,
    pray,
    tapestries,
    unicorn,
    hand,
    angel,
    horny,
    flowerglitch,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    text10,
    text11,
    text12,
    text13,
    text14,
    text15
];

const preloadImage = (src: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
    });
};

const Niko: React.FC = () => {
    const { setBackground } = useTheme();
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        setBackground(NikoColor);

        Promise.all(imageUrls.map(preloadImage))
            .then(() => setImagesLoaded(true))
            .catch((error) => {
                console.error("Failed to preload images:", error);
                setImagesLoaded(true); // Still show the content even if some images fail to load
            });
    }, [setBackground]);

    if (!imagesLoaded) {
        return <div className="niko"><div className={"loader text"}>Загрузка...</div></div>;
    }

    return (
        <div className="niko">
            <div className={"center"}>
                <img className={"gif-size"} src={title} alt="Title"/>
            </div>
            <div>
                <img className={"img-text"} src={text1} alt={"text1"}/>
            </div>
            <div>
                <img className={"img-text"} src={text2} alt={"text2"}/>
            </div>
            <div>
                <img className={"img-text"} src={text3} alt={"text3"}/>
            </div>
            <div className={"center"}>
                <img className={"gif-size"} src={man} alt="Title"/>
            </div>
            <div className={"center"}>
                <img className={"gif-size-small"} src={naked} alt="Title"/>
            </div>
            <div>
                <img className={"img-text"} src={text4} alt={"text4"}/>
            </div>
            <div>
                <img className={"img-text"} src={text5} alt={"text5"}/>
            </div>
            <div style={{height: "5rem"}}></div>
            <img className={"gif-size"} src={weed} alt="Title"/>
            <div>
                <img className={"img-text"} src={text6} alt={"text6"}/>
            </div>
            <div className={"center"}>
                <img className={"gif-size"} src={vagina} alt="Title"/>
            </div>
            <div>
                <img className={"img-text"} src={text7} alt={"text7"}/>
            </div>
            <img className={"gif-size"} src={pray} alt="Title"/>
            <div style={{height: "5rem"}}></div>
            <div>
                <img className={"img-text"} src={text8} alt={"text8"}/>
            </div>
            <div>
                <img className={"img-text"} src={text9} alt={"text9"}/>
            </div>
            <img className={"gif-size"} src={tapestries} alt="Title"/>
            <p></p>
            <div className={"inline"}>
                <img className={"gif-size-small"} src={unicorn} alt="Title"/>
                <img className={"gif-size-small"} src={hand} alt="Title"/>
            </div>
            <p></p>
            <img className={"gif-size-small"} src={horny} alt="Title"/>
            <div style={{height: "5rem"}}></div>
            <div>
                <img className={"img-text"} src={text10} alt={"text10"}/>
            </div>
            <div>
                <img className={"img-text"} src={text11} alt={"text11"}/>
            </div>
            <div>
                <img className={"img-text"} src={text12} alt={"text12"}/>
            </div>
            <div style={{height: "5rem"}}></div>
            <div className={"center"}>
                <img className={"gif-size"} src={angel} alt="Title"/>
            </div>
            <div style={{height: "5rem"}}></div>
            <div>
                <img className={"img-text"} src={text13} alt={"text13"}/>
            </div>
            <div className={"center"}>
                <img className={"gif-size"} src={flowerglitch} alt="Title"/>
            </div>
            <div>
                <img className={"img-text"} src={text14} alt={"text14"}/>
            </div>
            <img className={"gif-size"} src={cometa} alt="Title"/>
            <p></p>
            <div>
                <img style={{width: "60%"}} className={"img-text"} src={text15} alt={"text15"}/>
            </div>

        </div>
    );
};

export default Niko;
