import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ThemeProvider} from "./utils/ThemeProvider";
import {Black} from "./const/Colors";
import Homepage from "./components/homepage/Homepage";
import GameMB from "./components/authors/mb/Game";
import MB from "./components/authors/mb/MB";
import SKK from "./components/authors/skk/SKK";
import Niko from "./components/authors/niko/Niko";
import Tm from "./components/authors/tm/Tm";
import CollectionMB from "./components/authors/mb/Collection";

function App() {
  return (
      <ThemeProvider defaultColor={Black}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage/>}/>
              <Route path="/skk" element={<SKK/>}/>
              <Route path="/niko" element={<Niko/>}/>
              <Route path="/mb/game" element={<GameMB/>}/>
              <Route path="/mb/collection" element={<CollectionMB/>}/>
              <Route path="/mb" element={<MB/>}/>
              <Route path="/tm" element={<Tm/>}/>
            </Routes>
          </BrowserRouter>
      </ThemeProvider>
          );
}

export default App;
