import React from "react";
import "./Planets.css";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// Sedna
export const PlanetNiko: React.FC = () => {

    return (
        <div className="planet-niko">
            <Link to={"/niko"}>
            <div className="niko-planet"></div>
            </Link>
        </div>
    );
};

export const PlanetNikoDescription: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={"description-planet frame-doted"}>
            <div className={"description-planet-text"}>
                <p>{t('celestialObject')}: Sedna</p>
                <p>{t('workTitle')}: εὐφημία</p>
                <p>{t('workLanguages')}: ru</p>
                <p>{t('nkAuthors1')}</p>
                <p>{t('nkAuthors2')}</p>
            </div>
        </div>
    )
}
