import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useTheme} from "../../../utils/ThemeProvider";
import {MBColor} from "../../../const/Colors";
import "./MB.css"

const MB: React.FC = () => {
    const { setBackground } = useTheme();

    useEffect(() => {
        setBackground(MBColor);
    } )
    return (
        <div className={"mb"}>
            <div className={"fit"}>
                <div className={"menu"}>
                    <Link className={"text main-menu-buttons"} to={"/mb/game"}>ИГРАТЬ</Link>
                    <p></p>
                    <Link className={"text main-menu-buttons"} to={"/mb/collection"}>КОЛЛЕКЦИЯ</Link>
                </div>
            </div>
        </div>
    )
}

export default MB